import React from "react"
import PageBase from "../components/pageBase";
import ResearchImage from "../assets/img/product_research.webp"
import MonitorImage from "../assets/img/product_monitor.webp"
import WatchListImage from "../assets/img/product_watchlist.webp"
import ConnectImage from "../assets/img/product_connect.webp"

const ProductPage = () => {
    return (
        <PageBase>
          {/* return <h1>hi</h1>; */}
          <section className="wrapper bg-light">
            <div className="py-14 pt-md-17 container">
                <div className="mb-5 row">
                    <div className="mx-auto text-center col-lg-9 col-md-10 offset-lg-2 offset-md-1">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Migrations.ml</h2>
                        <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">Unbiased and granular credit insights to supercharge your research</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="mx-auto text-center col-lg-6 col-md-6 offset-lg-2 offset-md-1 mb-10">
                        <h3 className="mb-5">The world's most innovative fixed income market participants trust Migrations.ml to power their business</h3>
                    </div>
                </div>
                <div className="text-center mb-15 row">
                    <div className="col-xl-4 col-md-6">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <i className="uil uil-usd-circle" style={{ fontSize: '3rem'}}></i>
                                <h4>$3bn+ Global Hedge Fund</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <i className="uil uil-umbrella" style={{ fontSize: '3rem'}}></i>
                                <h4>Fortune 100 Insurer</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <i className="uil uil-server-network" style={{ fontSize: '3rem'}}></i>
                                <h4>Canadian Trading Platform</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Insight</h2>
                        <h3 className="display-4 mb-5">Supercharge your research</h3>
                        <p>Enhance your fixed income research with unbiased, predictive credit insights.</p>
                    </div>
                    <div className="col-lg-7">
                        <img src={ResearchImage} className="img-app-screenshot" />
                    </div>
                </div>
                <div className="mt-15 row">
                    <div className="col-lg-7">
                        <img src={MonitorImage} className="img-app-screenshot" />
                    </div>
                    <div className="col-lg-5">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Visualize</h2>
                        <h3 className="display-4 mb-5">Identify market opportunities</h3>
                        <p>Spot trends and discover inflection points faster with intuitive cloud UI.</p>
                    </div>
                </div>
                <div className="mt-15 row">
                    <div className="col-lg-5">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Monitor</h2>
                        <h3 className="display-4 mb-5">Keep tabs on the bond market</h3>
                        <p>Create personalized watchlists and get alerted on the biggest movers in the fixed income market.</p>
                    </div>
                    <div className="col-lg-7">
                        <img src={WatchListImage} className="img-app-screenshot" />
                    </div>
                </div>
                <div className="mt-15 row">
                    <div className="col-lg-7">
                        <img src={ConnectImage} className="img-insights" />
                    </div>
                    <div className="col-lg-5">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Connect</h2>
                        <h3 className="display-4 mb-5">Get insights any way you like</h3>
                        <p>Connect Migrations.ml to your Excel spreadsheet or data warehouse and start fetching daily market data in minutes.</p>
                    </div>
                </div>
            </div>
        </section>
        </PageBase>
      );
}

export default ProductPage;